<template>
    <div id="explore">
        <v-container fluid class="pt-0 pb-0 fill-height">
            <!-- 个人资料 -->
            <div class="headBox">
                <img :src="require('@/assets/user/userHead.png')" class="bg">
                <div class="userinfo">
                    <div class="userAvatar">
                        <v-list-item-avatar size="55">
                            <v-img :src="vuex_baseUrl+userData.base.headimg" :lazy-src="vuex_base64Avatar">
                            </v-img>
                        </v-list-item-avatar>
                        <div class="userName" v-if="userData.base">
                            {{userData.base.nickname}}
                        </div>
                        <v-btn class="red white--text ml-6" v-if="userData.status" @click="subscribeFunt(userData.base.id, 0, 3)">
                            {{userData.status==1?'未订阅':userData.status==2?'已订阅':'相互订阅'}}</v-btn>
                         <v-btn elevation="2" class="ml-3" v-if="userData.base.id!==vuex_user.id" @click="sendMessage">私信</v-btn>   
                    </div>
                    <div class="userData">
                        <div>
                            <p class="num">{{userData.count}}</p>
                            <p>作品 {{userData&&userData.headimg}}</p>
                        </div>
                        <div>
                            <p class="num">{{userData.fans}} </p>
                            <p>粉丝</p>
                        </div>
                        <div>
                            <p class="num">{{userData.follow}}</p>
                            <p>订阅</p>
                        </div>
                    </div>
                </div>
            </div>
          
            <!-- 菜单 -->
            <div class="top-warp">
                <v-row class="mt-5 text-center nav" no-gutters>
                    <v-col class="ml-7">
                        <div class="navitem" :style="{background:$vuetify.theme.dark?'#000':'#fff'}" :class="{'active':tabType==0}" @click="changeTab(0)">
                            <div class="ml-1">
                                <v-avatar size="26" class="ml-3" tile>
                                    <img :style="{ filter: $vuetify.theme.dark ? 'invert(100%)'
                                            : 'invert(0%)',
                                    }" alt="Avatar" src="@/assets/btn/video.png" />
                                </v-avatar>
                                频道
                            </div>
                        </div>
                    </v-col>
                    <v-col class="ml-3">
                        <div class="navitem" :style="{background:$vuetify.theme.dark?'#000':'#fff'}" :class="{'active':tabType==1}" @click="changeTab(1)">
                            <div class="ml-1">
                                <v-avatar size="26" class="ml-3" tile>
                                    <img :style="{ filter: $vuetify.theme.dark ? 'invert(100%)'
                                            : 'invert(0%)',
                                    }" alt="Avatar" src="@/assets/btn/art.png" />
                                </v-avatar>专栏
                            </div>
                        </div>
                    </v-col>
                    <v-col class="ml-3">
                        <div class="navitem" :style="{background:$vuetify.theme.dark?'#000':'#fff'}" :class="{'active':tabType==2}" @click="changeTab(2)">
                            <div class="ml-1">
                                <v-avatar size="26" class="ml-3" tile>
                                    <img :style="{ filter: $vuetify.theme.dark ? 'invert(100%)'
                                            : 'invert(0%)',
                                    }" alt="Avatar" src="@/assets/btn/photo.png" />
                                </v-avatar>
                                图墙
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <!--频道-->
            <mescroll-vue ref="mescroll0" v-show="tabType==0" :down="getMescrollDown(0)" :up="getMescrollUp(0)" @init="mescrollInit0">
                <v-row class="w100" id="dataList0" :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}">
                    <v-col v-if="tab0.list.length == 0" cols="12"></v-col>
                    <v-col v-for="(item) in tab0.list" :key="item.id" cols="12" sm="6" md="4" lg="3" class="mx-xs-auto">
                        <video-card :card="{ maxWidth: 350 }" :video="item" :channel="item" :collectionLists="collectionLists" @refresh="refresh" @getcollectionList="getcollectionList" @showCollect="showCollect"></video-card>
                    </v-col>
                </v-row>
            </mescroll-vue>
            <!--专栏-->
            <mescroll-vue ref="mescroll1" v-show="tabType==1" :up="getMescrollUp(1)" @init="mescrollInit1">
                <v-row class="w100" id="dataList1" :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}">
                    <v-col v-if="tab1.list.length == 0" cols="12"></v-col>
                    <v-col v-for="(item) in tab1.list" :key="item.id" cols="12" sm="6" md="4" lg="3" class="mx-xs-auto">
                        <video-card :card="{ maxWidth: 350 }" :video="item" :channel="item" :collectionLists="collectionLists" @refresh="refresh" @getcollectionList="getcollectionList" @showCollect="showCollect"></video-card>
                    </v-col>
                </v-row>
            </mescroll-vue>
            <!--图墙-->
            <mescroll-vue ref="mescroll2" v-show="tabType==2" :up="getMescrollUp(2)" @init="mescrollInit2">
                <v-row class="w100" id="dataList2" :style="{filter:$vuetify.theme.dark?'invert(100%)':'invert(0%)'}">
                    <v-col v-if="tab2.list.length == 0" cols="12"></v-col>
                    <v-col v-for="(item) in tab2.list" :key="item.id" cols="12" sm="6" md="4" lg="3" class="mx-xs-auto">
                        <video-card :card="{ maxWidth: 350 }" :video="item" :channel="item" :collectionLists="collectionLists" @refresh="refresh" @getcollectionList="getcollectionList" @showCollect="showCollect"></video-card>
                    </v-col>
                </v-row>
            </mescroll-vue>
             <msg-modal :open-dialog="msgDialog" :uId="uId" :uName="userData.base.nickname" v-on:closeDialog="modelClose" />
        </v-container>
    </div>
</template>

<script>
import videoCard from "@/components/VideoCard";
import MsgModal from "@/components/MsgModal";
import moment from "moment";
moment.locale("zh-cn");
// 引入mescroll的vue组件
import MescrollVue from "mescroll.js/mescroll.vue";
import { mapGetters } from "vuex";
// 模拟数据
// import mockData from "@/mock/pdlist";
// import CitySelect from "@/components/CitySelect.vue";

export default {
    name: "Home",
    components: {
        MescrollVue,
        videoCard,
        MsgModal
    },
    data() {
        return {
            typeArr: [],
            tab0: { mescroll: null, list: [], isListInit: false }, // 频道
            tab1: { mescroll: null, list: [], isListInit: false }, // 专栏
            tab2: { mescroll: null, list: [], isListInit: false }, // 图墙
            tabType: 0, // 菜单类型
            collectionLists: [], //收藏夹
            isAddcollection: false, //是否新增收藏夹
            overlay: false, //收藏弹框
            collectTxt: null,
            switch1: 1, //公开 私有
            routerAlive: false,
            isShow: false, //提示框
            showToast: null,
            currentId: null, //当前弹框id
            labelid: null, //检索条件标签id
            cityoverlay: false, //城市选择弹框
            uId: 0, //当前主页所属用户id
            userData: [], //用户资料
            msgDialog: false,
        };
    },
    mounted() {
        this.uId = this.$route.params.id;
        this.getUserBase();
        this.changeTab(0);
    },
    watch: {
        $route: {
            handler: function (val) {
                console.log(val);
                if (val.path.indexOf('channels') !== -1) {
                    this.uId = val.params.id;
                    this.getUserBase();
                    this.changeTab(0);
                }
            },
            // 深度观察监听
            deep: true,
        },
    },
    computed: {
        ...mapGetters(["getType"]),
    },
    methods: {
        modelClose() {
            this.msgDialog = false;
        },
        // 私信
        sendMessage(){
            this.msgDialog = true;
        },
        subscribeFunt(userId, idx, i) {
            //订阅||取消订阅
            let url = "/api/Explore/subscribe";
            this.$ajax
                .post(url, {
                    followid: userId,
                })
                .then((res) => {
                    this.showToast = res.msg;
                    this.isShow = true;
                    if (res.code == 1) {
                        if (i == 4) {
                            this.subscribe[idx].follow = res.status;
                        } else if (i == 5) {
                            this.fans[idx].follow = res.status;
                        } else if (i == 3) {
                            this.getUserBase();
                        }
                    }
                    // this.getList();
                });
        },
        // 获取用户基础信息
        getUserBase() {
            this.$ajax
                .post("/api/Basics/informationBase", {
                    id: this.uId,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.userData = res;
                        this.$forceUpdate();
                    }
                });
        },
        search(i) {
            // console.log("iiiii", i);
            this.labelid = i.key;
            this.changeTab(this.tabType);
        },
        closecityselect() {
            // console.log("15918919854");
            this.cityoverlay = false;
        },
        subscribe(userId, idx) {
            //订阅||取消订阅
            let url = "/api/Explore/subscribe";
            this.$ajax
                .post(url, {
                    followid: userId,
                })
                .then((res) => {
                    this.showToast = res.msg;
                    this.isShow = true;
                    if (res.code == 1) {
                        this.tab2.list[idx].follow = res.status;
                    }
                    // this.getList();
                });
        },
        setcollection(collectionId, resourcesId = this.currentId) {
            let url = "/api/Operation/favorite";
            this.$ajax
                .post(url, {
                    fid: collectionId,
                    rid: resourcesId,
                    type: this.getType,
                })
                .then((res) => {
                    this.showToast = res.msg;
                    this.isShow = true;
                });
        },
        showCollect(videoId) {
            this.currentId = videoId;
            this.overlay = true;
        },
        getcollectionList() {
            let url = "/api/Operation/getcollection";
            this.$ajax.post(url, {}).then((res) => {
                this.collectionLists = res.folder;
            });
        },
        toaddcollection() {
            let url = "/api/Operation/collection";
            this.$ajax
                .post(url, {
                    name: this.collectTxt,
                    privacy: this.switch1,
                })
                .then((res) => {
                    this.isAddcollection = false;
                    if (res.code == "1") {
                        this.getcollectionList();
                        this.showToast = res.msg;
                        this.isShow = true;
                    }
                });
        },
        refresh() {
            this.changeTab(this.tabType);
        },
        initialLabel() {
            //获取标签
            let url = "/api/Index/getlabel";
            this.$ajax.post(url, {}).then((res) => {
                if (res.code == 1) {
                    let arr = res.data;
                    let typeArr = [
                        {
                            txt: "全部",
                            key: null,
                        },
                    ];
                    for (let i in arr) {
                        typeArr.push({
                            txt: arr[i].lname,
                            key: arr[i].id,
                        });
                    }

                    typeArr.sort(function (a, b) {
                        return a.key - b.key;
                    });
                    this.typeArr = typeArr;
                }
            });
        },
        // mescroll组件初始化的回调,可获取到mescroll对象
        mescrollInit0(mescroll) {
            mescroll.tabType = 0; // 加入标记,便于在回调中取到对应的list
            this.tab0.mescroll = mescroll;
        },
        mescrollInit1(mescroll) {
            mescroll.tabType = 1;
            this.tab1.mescroll = mescroll;
        },
        mescrollInit2(mescroll) {
            mescroll.tabType = 2;
            this.tab2.mescroll = mescroll;
        },
        // 多mescroll的配置,需通过方法获取,保证每个配置是单例
        getMescrollDown(tabType) {
            let isAuto = tabType === 0; // 第一个mescroll传入true,列表自动加载
            return {
                auto: isAuto,
                callback: this.downCallback,
            };
        },
        getMescrollUp(tabType) {
            let emptyWarpId = "dataList" + tabType;
            return {
                auto: false,
                callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page) { upCallback(page); }
                noMoreSize: 4, // 如果列表已无数据,可设置列表的总数量要大于半页才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看; 默认5
                empty: {
                    warpId: emptyWarpId, // 父布局的id;
                    icon: require("@/assets/icons/mescroll-empty.png"),
                    //  " http://www.mescroll.com/img/mescroll-empty.png", // 图标,默认null
                    tip: "暂无相关数据~", // 提示
                    // btntext: "去逛逛 >", // 按钮,默认""
                    // btnClick: function () {
                    //   // 点击按钮的回调,默认null
                    //   alert("点击了按钮,具体逻辑自行实现");
                    // },
                },
                toTop: {
                    // 配置回到顶部按钮
                    src: require("@/assets/icons/mescroll-totop.png"), // 图片路径,默认null (建议写成网络图,不必考虑相对路径)
                },
            };
        },
        /* 下拉刷新的回调 */
        downCallback(mescroll) {
            mescroll.resetUpScroll(); // 触发下拉刷新的回调,加载第一页的数据
        },
        /* 上拉加载的回调 page = {num:1, size:10}; num:当前页 从1开始, size:每页数据条数 */
        upCallback(page, mescroll) {
            page.size = 12;
            console.log('mescroll.tabTypemescroll.tabType',mescroll.tabType);

            if (mescroll.tabType === 0) {
                // 全部
                // this.tab0.isListInit = true; // 标记列表已初始化,保证列表只初始化一次
                this.getListDataFromNet(
                    mescroll.tabType,
                    page.num,
                    page.size,
                    (curPageData) => {
                        console.log("curPageData", curPageData);
                        mescroll.endSuccess(curPageData.length); // 联网成功的回调,隐藏下拉刷新和上拉加载的状态;
                        if (page.num === 1) this.tab0.list = []; // 如果是第一页需手动制空列表
                        this.tab0.list = this.tab0.list.concat(curPageData); // 追加新数据
                    },
                    () => {
                        if (page.num === 1) this.tab0.isListInit = false;
                        mescroll.endErr(); // 联网失败的回调,隐藏下拉刷新的状态
                    }
                );
            } else if (mescroll.tabType === 1) {
                // this.tab1.isListInit = true; // 标记列表已初始化,保证列表只初始化一次
                this.getListDataFromNet(
                    mescroll.tabType,
                    page.num,
                    page.size,
                    (curPageData) => {
                        // console.log("curPageData", mescroll);
                        mescroll.endSuccess(curPageData.length); // 联网成功的回调,隐藏下拉刷新和上拉加载的状态;
                        if (page.num === 1) {this.tab1.list = []}; // 如果是第一页需手动制空列表
                        this.tab1.list = this.tab1.list.concat(curPageData); // 追加新数据
                    },
                    () => {
                        if (page.num === 1) this.tab1.isListInit = false;
                        mescroll.endErr(); // 联网失败的回调,隐藏下拉刷新的状态
                    }
                );
            } else if (mescroll.tabType === 2) {
                // 面膜
                // this.tab2.isListInit = true; // 标记列表已初始化,保证列表只初始化一次
                this.getListDataFromNet(
                    mescroll.tabType,
                    page.num,
                    page.size,
                    (curPageData) => {
                        mescroll.endSuccess(curPageData.length); // 联网成功的回调,隐藏下拉刷新和上拉加载的状态;
                        if (page.num === 1) this.tab2.list = []; // 如果是第一页需手动制空列表
                        this.tab2.list = this.tab2.list.concat(curPageData); // 追加新数据
                    },
                    () => {
                        if (page.num === 1) this.tab2.isListInit = false;
                        mescroll.endErr(); // 联网失败的回调,隐藏下拉刷新的状态
                    }
                );
            }
        },
        // 切换菜单
        changeTab(type) {
            let modearr = ["video", "art", "photo"];
            this.vuex("vuex_mode", modearr[type]);
            let curTab = this.getTabData(this.tabType); // 当前列表
            let newTab = this.getTabData(type); // 新转换的列表
            curTab.mescroll.hideTopBtn(); // 隐藏当前列表的回到顶部按钮
            this.tabType = type; // 切换菜单
            if (!newTab.isListInit) {
                console.log(newTab);
                newTab.mescroll.resetUpScroll(); // 加载列表
            } else {
                setTimeout(() => {
                    // 检查新转换的列表是否需要显示回到到顶按钮
                    var curScrollTop = newTab.mescroll.getScrollTop();
                    if (curScrollTop >= newTab.mescroll.optUp.toTop.offset) {
                        newTab.mescroll.showTopBtn();
                    } else {
                        newTab.mescroll.hideTopBtn();
                    }
                }, 30);
            }
        },
        // 获取菜单对应的数据
        getTabData(tabType) {
            if (tabType == null) tabType = this.tabType;
            if (tabType === 0) {
                return this.tab0;
            } else if (tabType === 1) {
                return this.tab1;
            } else if (tabType === 2) {
                return this.tab2;
            } else if (tabType === 3) {
                return this.tab3;
            } else if (tabType === 4) {
                return this.tab4;
            }
        },
        /* 联网加载列表数据
      在您的实际项目中,请参考官方写法: http://www.mescroll.com/api.html#tagUpCallback
      请忽略getListDataFromNet的逻辑,这里仅仅是在本地模拟分页数据,本地演示用
      实际项目以您服务器接口返回的数据为准,无需本地处理分页.
      * */
        getListDataFromNet(
            tabType,
            pageNum,
            pageSize,
            successCallback,
            errorCallback
        ) {
            // 延时一秒,模拟联网
            let url = "/api/Homepage/channellist";
            if (tabType == 1) {
                url = "/api/Homepage/specialcolumnlist";
            } else if (tabType == 2) {
                url = "/api/Homepage/platelist";
            }
            let data = {
                page: pageNum,
                limit: pageSize,
            };
            // if (tabType == 0) {
            data = { ...data, id: this.uId };
            // }
            this.$ajax.post(url, data).then((res) => {
                if (res.code == 1) {
                    let arr = [];
                    arr = res.data;
                    // console.log(tabType, arr);
                    arr.map((item, val) => {
                        arr[val].uid = this.uId;
                        let name = tabType === 0 ? item.vname : tabType == 1 ? item.aname : item.pname;
                        // console.log(name, arr);
                        if (item.state == 1 && this.vuex_user && this.uId == this.vuex_user.id) {
                            arr[val].name = '[未审核] ' + name;
                            tabType === 2 ? arr[val].pname = '[未审核] ' + name : '';
                        } else if (
                            item.state == 2 && this.vuex_user && this.uId == this.vuex_user.id
                        ) {
                            arr[val].name = "[未通过] " + name;
                            tabType === 2 ? arr[val].pname = '[未通过] ' + name : '';
                        } else {
                            arr[val].name = name;
                        }
                    });
                    successCallback && successCallback(arr);
                } else {
                    errorCallback && errorCallback();
                }
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        // 如果没有配置回到顶部按钮或isBounce,则beforeRouteEnter不用写
        next((vm) => {
            vm.vuex("vuex_mode", "video");
            let curMescroll = vm.$refs["mescroll" + vm.tabType]; // 找到当前mescroll的ref,调用子组件mescroll-vue的beforeRouteEnter方法
            curMescroll && curMescroll.beforeRouteEnter(); // 进入路由时,滚动到原来的列表位置,恢复回到顶部按钮和isBounce的配置
        });
    },
    beforeRouteLeave(to, from, next) {
        // 如果没有配置回到顶部按钮或isBounce,则beforeRouteLeave不用写
        let curMescroll = this.$refs["mescroll" + this.tabType]; // 找到当前mescroll的ref,调用子组件mescroll-vue的beforeRouteEnter方法
        curMescroll && curMescroll.beforeRouteLeave(); // 退出路由时,记录列表滚动的位置,隐藏回到顶部按钮和isBounce的配置
        next();
    },
};
</script>

<style lang="scss" scoped>
.headBox {
    width: 100%;
    height: 220px;
    overflow: hidden;
    position: relative;
    .bg {
        height: auto;
        min-height: 100%;
        width: 100%;
    }
    .userinfo {
        position: absolute;
        left: 50px;
        top: 42px;
        height: 220px;
        color: #fff;
        .userAvatar {
            display: flex;
            align-items: center;
            font-size: 20px;
        }
        .userData {
            margin-left: -50px;
            font-size: 14px;
            display: flex;
            padding-top: 20px;
            div {
                width: 180px;
                text-align: center;
                position: relative;
                .num {
                    font-weight: bold;
                    font-size: 15px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 20%;
                    width: 2px;
                    height: 50%;
                    background-color: #fff;
                }
                &:last-child:before {
                    display: none;
                }
            }
        }
    }
}
.w100 {
    width: 100%;
}
.checkchip {
    background: #848484 !important;
    color: #fafafa !important;
}
.checkchipdark {
    background: #000000 !important;
    color: #ffffff !important;
}
/*以fixed的方式固定mescroll的高度*/
.mescroll {
    height: 500px;
    margin-top: 20px;
}

.top-warp {
    width: 100%;
}
.active {
    font-weight: bold;
}
.navitem {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navitem img {
    width: 24px;
    height: 24px;
}

/*展示上拉加载的数据列表*/
.data-li {
    position: relative;
    height: 80px;
    padding: 10px 8px 10px 120px;
    border-bottom: 1px solid #eee;
}
.data-title {
    font-weight: bold;
}
.data-li .pd-img {
    position: absolute;
    left: 18px;
    top: 10px;
    width: 80px;
    height: 80px;
}
.data-li .pd-name {
    font-size: 13px;
    line-height: 20px;
    height: 40px;
    margin-bottom: 10px;
    overflow: hidden;
}
.data-li .pd-price {
    font-size: 13px;
    color: red;
}
.data-li .pd-sold {
    font-size: 12px;
    margin-top: 8px;
    color: gray;
}
</style>
