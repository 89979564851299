<template>
    <div id="issue">
        <v-container fluid>
            <v-card-title>发布专栏</v-card-title>
            <v-row class="px-sm-10">
                <v-col cols="12" sm="9" md="8" lg="7">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field v-model="title" label="标题" :rules="titleRules" required></v-text-field>
                        <!-- <v-text-field v-model="intro" label="简介" :rules="[v => !!v || '简介不能为空']" required></v-text-field> -->
                        <v-file-input ref="imgInp" truncate-length="15" :rules="imgRules" :loading="imgLoading" show-size label="封面图" @change="upImg" @click:clear="clearImg()" required accept="image/*"></v-file-input>
                        <v-row>
                            <v-col cols="12" sm="3" md="3" lg="2" v-for="(item,index) in labelList" :key="index">
                                <v-checkbox v-model="checkbox" :label="item.lname" :value="item.id"></v-checkbox>
                            </v-col>
                        </v-row>
                        <div class="diyTab d-flex align-center" v-for="(item,index) in diyTab" :key="index">
                            <v-text-field v-model="item.value" label="添加一个自定义标签" class="mr-5"></v-text-field>
                            <v-btn elevation="2" icon @click="delTab(index)" class="mr-3">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-btn elevation="2" icon @click="addTab(index)">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                    </v-form>
                </v-col>
                <v-col cols="10">
                    <div id="wangeditor"></div>
                </v-col>
                <v-col cols="12">
                    <v-btn class="mt-5 mb-15" color="primary" @click="submitAdd()">
                        提交
                    </v-btn>
                </v-col>
            </v-row>
            <v-snackbar v-model="isShow" :timeout="1500">
                {{showToast}}
            </v-snackbar>
            <v-dialog v-model="dialog" persistent max-width="500">
                <v-card>
                    <div class="d-flex justify-space-between mb-5" id="modal-header">
                        <v-card-title class="py-3">请先创建专栏</v-card-title>
                        <div class="mt-3 mr-2">
                            <v-btn icon text @click="closeModal">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-card-text class="d-flex flex-column pt-5 pb-15">
                        <v-text-field v-model="folderName" label="名称"></v-text-field>
                        <v-btn class="mt-5" color="primary" @click="addFolder()">
                            提交
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>

    </div>
</template>

<script>
import E from "wangeditor"
let editor;
export default {
    data: () => ({
        valid: true,
        title: '',
        titleRules: [
            v => !!v || '标题不能为空'
        ],
        intro: '',
        diyTab: [{
            value: ''
        }],
        imgRules: [
            value => !!value || '封面图不能为空',
            value => !value || value.size < 2000000 || '封面图不能大于2MB！',],
        img: '',
        imgLoading: false,
        checkbox: [],
        labelList: [],
        type: 2,  //1=>频道,2=>专栏,3=>图墙
        isShow: false,
        showToast: '',
        dialog: false,
        folderName: '',  //创建的频道名称
    }),

    mounted() {
        this.editorInitial();
        this.getLabel();
        this.getFolder();
    },
    methods: {
        editorInitial() {
            editor = new E('#wangeditor');
            editor.config.withCredentials = true;
            editor.config.uploadImgServer = this.vuex_baseUrl + '/api/Homepage/UpFiles'
            editor.config.height = 500;
            editor.config.zIndex = 1;
            editor.config.uploadFileName = 'file';
            editor.config.uploadImgHooks = {

                customInsert: function (insertImgFn, result) {
                    // result 即服务端返回的接口
                    console.log('customInsert', result)
                    // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
                    insertImgFn(result.data[0])
                }
            }
            editor.create()
        },

        // 查看是否创建
        getFolder(type = this.type) {
            this.$ajax.get('/api/Homepage/getfolder?type=' + type).then(res => {
                if (res.code == 1) {
                    this.flag = res.id;
                } else {
                    this.dialog = true;
                }
            })
        },
        // 创建频道
        addFolder() {
            if (this.folderName == '') {
                return false;
            }
            this.$ajax.post('/api/Homepage/folder', {
                type: this.type,
                name: this.folderName
            }).then(res => {
                if (res.code == 1) {
                    this.dialog = false;
                    this.showToast = '创建成功';
                    this.getFolder();
                    setTimeout(() => {
                        this.submitAdd();
                    }, 1500)
                } else {
                    this.dialog = true;
                    this.showToast = res.msg;
                }
                this.isShow = true;
            })
        },
        closeModal() {
            this.dialog = false;
        },
        submitAdd() {
            let that = this;
            if (!this.flag) {
                this.dialog = true;
                return false;
            }
            if (!this.$refs.form.validate()) return;
            let diyT = [];
            for (let i = 0; i < this.diyTab.length; i++) {
                if (this.diyTab[i]) {
                    diyT.push(this.diyTab[i].value)
                }
            }
            this.$ajax.post('/api/Homepage/uploadarticle', {
                sid: this.flag,
                name: this.title,
                imgid: this.img.id,
                article: editor.txt.html(),
                number: editor.txt.text().length,
                introduction: this.intro,
                type: this.checkbox ? this.checkbox.join(',') : '',
                custom: diyT.join(',')
            }).then(res => {
                console.log(res);
                let url = '/channels/' + this.vuex_user.id
                if (res.code == 1) {
                    setTimeout(function () {
                        that.$router.push(url)
                    }, 1500)
                    this.$refs.form.reset();
                    this.$refs.imgInp.$refs.input.value = '';
                    editor.txt.clear();
                }
                this.isShow = true;
                this.showToast = res.msg;
            })
        },
        // 获取标签
        getLabel() {
            this.$ajax.post('/api/Index/getlabel').then(res => {
                if (res.code == 1) {
                    this.labelList = res.data;
                }
            })
        },
        clearImg() {
            this.imgLoading = false;
            this.img = '';
        },
        // 上传图片
        upImg(file) {
            if (!file) return;
            if (!file.type.match("image")) {
                this.img = '';
                this.showToast = '格式不符';
                this.isShow = true;
                this.$refs.imgInp.reset();
                return
            }
            this.imgLoading = true;
            this.upFile(file).then(res => {
                this.imgLoading = false;
                this.img = res.data;
            });
        },
        upFile(file) {
            return new Promise((resolve, reject) => {
                let formDatas = new FormData();
                formDatas.append('file', file);
                this.$ajax.post('/api/Homepage/UpFiles', formDatas, { headers: { 'Conten-Type': 'multipart/form-data' } }).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                });
            })
        },
        //添加自定义标签
        addTab(index) {
            this.diyTab.push({
                value: ''
            })
        },

        delTab(index) {
            if (this.diyTab.length == 1) {
                this.diyTab[0].value = '';
            } else {
                this.diyTab.splice(index, 1);
            }
        },
    },
}
</script>
