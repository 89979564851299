<template>
    <div id="issue">
        <v-container fluid>
            <v-card-title>发布频道</v-card-title>
            <v-row class="px-sm-10">
                <v-col cols="12" sm="9" md="8" lg="7">
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field v-model="title" label="标题" :rules="titleRules" required></v-text-field>
                        <!-- <v-text-field v-model="intro" label="简介" :rules="[v => !!v || '简介不能为空']" required></v-text-field> -->
                        <v-file-input ref="videoInp" truncate-length="15" accept="video/*" :rules="videoRules" :loading="videoLoading" show-size label="视频文件" @change="upVideo" @click:clear="clearVideo()" required>
                        </v-file-input>
                        <!-- <v-file-input ref="imgInp" truncate-length="15" accept="image/*" :rules="imgRules" :loading="imgLoading"
              show-size label="封面图" @change="upImg" @click:clear="clearImg()" required></v-file-input> -->
                        <v-row>
                            <v-col cols="12" sm="3" md="3" lg="2" v-for="(item,index) in labelList" :key="index">
                                <v-checkbox v-model="checkbox" :label="item.lname" :value="item.id"></v-checkbox>
                            </v-col>
                        </v-row>
                        <div class="diyTab d-flex align-center" v-for="(item,index) in diyTab" :key="index">
                            <v-text-field v-model="item.value" label="添加一个自定义标签" class="mr-5"></v-text-field>
                            <v-btn elevation="2" icon @click="delTab(index)" class="mr-3">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-btn elevation="2" icon @click="addTab(index)">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                        <v-btn class="mt-15" color="primary" @click="submitAdd()">
                            提交
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
            <v-snackbar v-model="isShow" :timeout="1500">
                {{showToast}}
            </v-snackbar>
            <v-dialog v-model="dialog" persistent max-width="500">
                <v-card>
                    <div class="d-flex justify-space-between mb-5" id="modal-header">
                        <v-card-title class="py-3">请先创建频道</v-card-title>
                        <div class="mt-3 mr-2">
                            <v-btn icon text @click="closeModal">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    <v-card-text class="d-flex flex-column pt-5 pb-15">
                        <v-text-field v-model="folderName" label="名称"></v-text-field>
                        <v-btn class="mt-5" color="primary" @click="addFolder()">
                            提交
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
        <video style="display:none;" controls="controls" ref="videoBox" :src="vuex_baseUrl+video.path">
        </video>
    </div>
</template>

<script>
import { createLogger } from 'vuex';
// import { createLogger } from 'vuex';
export default {
    data: () => ({
        valid: true,
        title: '',
        titleRules: [
            v => !!v || '标题不能为空'
        ],
        intro: '',
        video: '',
        videoRules: [
            value => !!value || '视频不能为空',
            value => !value || value.size < 50000000 || '视频不能大于50MB！'
        ],
        videoLoading: false,
        diyTab: [{
            value: ''
        }],
        imgRules: [
            value => !!value || '封面图不能为空',
            value => !value || value.size < 2000000 || '封面图不能大于2MB！',],
        img: '',
        imgLoading: false,
        checkbox: [],
        labelList: [],
        type: 1,
        isShow: false,
        showToast: '',
        dialog: false,
        folderName: '',  //创建的频道名称
    }),
    mounted() {
        this.getLabel();
        this.getFolder();
    },
    methods: {

        // 查看是否创建
        getFolder(type = this.type) {
            this.$ajax.get('/api/Homepage/getfolder?type=' + type).then(res => {
                if (res.code == 1) {
                    this.flag = res.id;
                } else {
                    this.dialog = true;
                }
            })
        },
        // 创建频道
        addFolder() {
            if (this.folderName == '') {
                return false;
            }
            this.$ajax.post('/api/Homepage/folder', {
                type: this.type,
                name: this.folderName
            }).then(res => {
                if (res.code == 1) {
                    this.dialog = false;
                    this.showToast = '创建成功';
                    this.getFolder();
                    setTimeout(() => {
                        this.submitAdd();
                    }, 1500)
                } else {
                    this.dialog = true;
                    this.showToast = res.msg;
                }
                this.isShow = true;
            })
        },
        closeModal() {
            this.dialog = false;
        },
        submitAdd() {
            let that = this;
            if (!this.flag) {
                this.dialog = true;
                return false;
            }
            if (!this.$refs.form.validate()) return;
            let diyT = [];
            for (let i = 0; i < this.diyTab.length; i++) {
                if (this.diyTab[i]) {
                    diyT.push(this.diyTab[i].value)
                }
            }
            this.$ajax.post('/api/Homepage/uploadvideo', {
                cid: this.flag,
                name: this.title,
                imgid: this.img.id,
                video: this.video.path,
                width: this.video.width,
                height: this.video.height,
                duration: this.video.duration || 0,
                introduction: this.intro,
                type: this.checkbox ? this.checkbox.join(',') : '',
                custom: diyT.join(',')
            }).then(res => {
                console.log(res);
                let url = '/channels/' + this.vuex_user.id
                if (res.code == 1) {
                    setTimeout(function () {
                        that.$router.push(url)
                    }, 1500)
                    this.$refs.videoInp.$refs.input.value = ''
                    this.$refs.form.reset();
                }
                this.isShow = true;
                this.showToast = res.msg;
            })
        },
        // 获取标签
        getLabel() {
            this.$ajax.post('/api/Index/getlabel').then(res => {
                if (res.code == 1) {
                    this.labelList = res.data;
                }
            })
        },
        // 上传视频
        upVideo(file) {
            if (!file) return;
            if (!file.type.match("video")) {
                this.showToast = '格式不符';
                this.isShow = true;
                this.$refs.videoInp.reset();
                return
            }
            this.videoLoading = true;
            this.upFile(file).then(res => {
                this.videoLoading = false;
                this.video = res.data;
                setTimeout(() => {
                    var video = this.$refs.videoBox;
                    this.video.width = video.videoWidth;
                    this.video.duration = video.duration;
                    this.video.height = video.videoHeight;
                }, 800)

                // var video = document.querySelector('video');
                // video.addEventListener('canplay', function () {
                //   this.width = this.videoWidth;
                //   this.height = this.videoHeight;
                // });
            });
        },
        clearVideo() {
            this.videoLoading = false;
            this.video = '';
        },
        clearImg() {
            this.imgLoading = false;
            this.img = '';
        },
        // 上传图片
        upImg(file) {
            if (!file) return;
            if (!file.type.match("image")) {
                this.img = '';
                this.showToast = '格式不符';
                this.isShow = true;
                this.$refs.imgInp.reset();
                return
            }
            this.imgLoading = true;
            this.upFile(file).then(res => {
                this.imgLoading = false;
                this.img = res.data;
            });
        },
        upFile(file) {
            return new Promise((resolve, reject) => {
                let formDatas = new FormData();
                formDatas.append('file', file);
                this.$ajax.post('/api/Homepage/UpFiles', formDatas, { headers: { 'Conten-Type': 'multipart/form-data' } }).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                });
            })
        },
        //添加自定义标签
        addTab(index) {
            this.diyTab.push({
                value: ''
            })
        },

        delTab(index) {
            if (this.diyTab.length == 1) {
                this.diyTab[0].value = '';
            } else {
                this.diyTab.splice(index, 1);
            }
        },
    },
}
</script>