<template>
    <v-dialog v-model="dialog" persistent transition="fab-transition" max-width="600" id="settings">
        <v-card tile>
            <div class="d-flex justify-space-between mb-5" id="modal-header">
                <v-card-title class="py-3 text-subtitle-1">{{uName||'私信'}}</v-card-title>
                <v-btn icon text @click="closeModal">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <!-- 消息详情 111-->
            <v-card class="cardBody" ref="cardBody" height="520">
                <div>
                    <div v-if="isMore==2" class="d-flex justify-center msgMore" @click="msgDetails(msgId)">
                        查看更多
                    </div>
                    <div v-else class="d-flex justify-center msgMore">
                        已无更多消息
                    </div>
                </div>
                <div class="d-flex flex-column msgBox">
                    <div class="py-2 px-3" :class="item.sender==vuex_user.id?'align-self-end':'align-self-start'" v-for="(item,index) in msgData" :key="index">
                        <!-- 用户间私信 -->
                        <div :class="item.sender==vuex_user.id?'msg-right':''">
                            {{ $util.getDateDiff(new Date(item.time).valueOf()) }}</div>
                        <div class="msgCont" :class="item.sender==vuex_user.id?'msgCont-right':''">
                            <v-img min-height="150" max-height="150" max-width="200" v-if="item.image" :src="vuex_baseUrl+item.image">
                            </v-img>
                            <div>{{item.content}}</div>
                        </div>
                    </div>
                </div>
            </v-card>
            <!-- 回复 -->
            <div class="msgReply d-flex align-center px-2">
                <v-text-field v-model="msgReplyText" v-on:keyup.enter="sendMsg"></v-text-field>
                <v-btn slot="append" class="ml-2" style="background: #19be6b;color:#fff" @click="sendMsg">发送</v-btn>
            </div>
            <v-snackbar :timeout="1500" v-model="isShow">
                {{showToast}}
            </v-snackbar>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'MsgModal',
    props: ['openDialog', 'uId', 'uName'],
    data: function () {
        return {
            isShow: false,
            showToast: '',
            msgId: '',
            msgData: [],
            msgPage: 1,
            msgReplyText: '',
            isMore: '',
        }
    },
    computed: {
        dialog() {
            return this.openDialog
        }
    },
    created() {
        this.msgId = this.uId;
        this.msgDetails(this.msgId);
    },
    watch: {
        uId(val) {
            console.log('uid变化', val);
            this.msgDetails(val);
        }
    },
    methods: {
        //发送私信
        sendMsg() {
            let that = this;
            if (that.msgReplyText != '') {
                this.$ajax.post('/api/Operation/message', {
                    leader: that.msgId,
                    content: that.msgReplyText
                }).then(res => {
                    that.$refs.cardBody.$el.scrollTop = that.$refs.cardBody.$el.scrollHeight;
                    that.msgPage = 1;
                    that.msgReplyText = '';
                    that.msgDetails(that.msgId);
                })
            }
        },
        // 获取消息详情
        msgDetails(id) {
            let that = this;
            let url = '/api/News/messagedetail';
            if (id != this.msgId) {
                this.msgId = id;
                this.msgPage = 1;
                this.isMore = '';
            }
            this.$ajax.post(url, {
                id: this.msgId,
                page: that.msgPage

            }).then(res => {
                this.reveal = true;
                if (that.msgPage == 1) { that.msgData = [] }
                for (let i = res.data.length - 1; i >= 0; i--) {
                    that.msgData.unshift(res.data[i]);
                }
                console.log(that.msgData);
                if (res.page.maxPage > that.msgPage) { this.isMore = 2 }
                else {
                    this.isMore = 1
                }
                that.msgPage++;
            })
        },
        closeModal() {
            this.$emit('closeDialog')
        },
    },
}
</script>

<style lang="scss" scoped>
.msgBox {
    padding-bottom: 120px;
}
.msgAlert {
    background: linear-gradient(-6deg, #000000, #7a7a7a);
    border-radius: 10px;
    color: #fff;
    font-size: 10px;
    padding: 0 5px;
}
.msgReply {
    position: absolute;
    width: 100%;
    bottom: 0;
    background: #fff;
}
.msg-right {
    text-align: right;
}

.msgMore {
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 10px;
}
.cardBody {
    overflow-y: scroll;
}
.cardBody::-webkit-scrollbar {
    width: 5px;
    background: #ededed;
}
.cardBody::-webkit-scrollbar-thumb {
    width: 5px;
    background: #929292;
    border-radius: 5px;
    cursor: pointer;
}
.msgCont {
    background: #f1f2f3;
    padding: 10px 15px;
    border-radius: 5px;
    margin-top: 5px;
    max-width: 230px;
}
.msgCont-right {
    background: #19be6b;
    color: #fff;
}
.msgCont .v-image {
    margin-bottom: 5px;
}
</style>
